import React, { useEffect } from 'react'
import { Accordion, Border, Box, Button, Checkbox, DropDown, Divider, Grid, Icon, Image, Layer, RadioButtons, DatePicker, ResponsiveWrapper, PhoneScreen, TabletScreen, LaptopScreen, DesktopScreen, Spacer, Text, TextArea, TextField, Underline } from '../Elements'
import { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6, Map } from '../Components'
import { FiMenu } from 'react-icons/fi'
import { c_accentFont, c_businessName, c_footer, c_nav, c_routes } from '../../Constants'
import { useLocation, useNavigate } from 'react-router'
import { home4_buttonNav1, home4_buttonText1, home4_caption1, home4_caption2, home4_heading1, home4_heading2, home4_subBlocks } from '../../SprintConstants'

import img1 from '../../PHOTOS/stock4.jpg'

export default function Home4() {
    const navigate = useNavigate()
    const location = useLocation()
    const currentLocation = location.pathname

    // function openNav() {
    //     if (window.innerWidth < 600) {
    //         document.querySelector(".nav-body").style.width = "100vw";
    //     } else if (window.innerWidth < 800) {
    //         document.querySelector(".nav-body").style.width = "50vw";
    //     } else if (window.innerWidth < 1000) {
    //         document.querySelector(".nav-body").style.width = "40vw";
    //     } else if (window.innerWidth < 1200) {
    //         document.querySelector(".nav-body").style.width = "35vw";
    //     } else {
    //         document.querySelector(".nav-body").style.width = "20vw";
    //     }
    // }

    // function closeNav() {
    //     document.querySelector(".nav-body").style.width = "0";
    // }

    useEffect(() => {
        window.scrollTo(0, 0)
        // closeNav()
    }, [])

    return (
        <div className='roboto fade-in'>
            {c_nav()}

            {/* TOP */}
            <ResponsiveWrapper>
                <PhoneScreen>
                    <Box width="" height="" radius="" backgroundColor="" paddingV="1em" paddingH="" id="" classes={``}>
                        <Grid orientation="column" template="1fr" gap="1em" classes={``}>
                            <Box width="" height="" radius="" backgroundColor="" paddingV="10vh" paddingH="1em" id="" classes={`dark`}>
                                <Text text={`${home4_heading1}`} fontSize="10vw" weight="600" color="white" spacing="" lineHeight="1em" classes={`${c_accentFont} color1`} />
                                <Spacer height={`2vh`} />
                                <Text text={`${home4_caption1}`} fontSize="4vw" weight="300" color="" spacing="" lineHeight="" classes={``} />
                                <Spacer height={`4vh`} />
                                <Button color="" backgroundColor="" radius="" func={() => { navigate(`/${home4_buttonNav1}`) }} classes={``}>
                                    <Box width="fit-content" height="" radius="" backgroundColor="" paddingV="0.8em" paddingH="1.5em" id="" classes={`center light`}>
                                        <Text text={`${home4_buttonText1}`} fontSize="" weight="" color="" spacing="" lineHeight="" classes={``} />
                                    </Box>
                                </Button>
                            </Box>
                            <Box width="" height="40vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                <Image src={img1} alt="" radius="" classes={` fill`} />
                            </Box>
                        </Grid>
                        <Spacer height={`6vh`} />
                        <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                            <Text text={`${home4_heading2}`} fontSize="1.5em" weight="500" color="" spacing="" lineHeight="1em" classes={`${c_accentFont} color1`} />
                            <Spacer height={`2vh`} />
                            <Text text={`${home4_caption2}`} fontSize="1em" weight="300" color="" spacing="" lineHeight="" classes={``} />
                            <Spacer height={`6vh`} />
                            <Grid orientation="column" template="1fr" gap="1em" classes={``}>
                                {
                                    home4_subBlocks.map((block, i) => {
                                        return (
                                            <Box width="" height="" radius="" backgroundColor="rgba(0,0,80,0.05)" paddingV="1em" paddingH="" id="" classes={``}>
                                                <Text text={`${block.Heading}`} fontSize="1.2em" weight="400" color="" spacing="" lineHeight="" classes={`${c_accentFont} color1`} />
                                                <Spacer height={`2vh`} />
                                                <Text text={`${block.Caption}`} fontSize="0.9em" weight="300" color="" spacing="" lineHeight="" classes={``} />
                                            </Box>
                                        )
                                    })
                                }
                            </Grid>
                        </Box>
                    </Box>
                </PhoneScreen>
                <TabletScreen>
                    <Box width="" height="" radius="" backgroundColor="" paddingV="1em" paddingH="" id="" classes={``}>
                        <Grid orientation="column" template="1fr" gap="1em" classes={``}>
                            <Box width="" height="" radius="" backgroundColor="" paddingV="10vh" paddingH="2em" id="" classes={`dark`}>
                                <Text text={`${home4_heading1}`} fontSize="10vw" weight="600" color="white" spacing="" lineHeight="1em" classes={`${c_accentFont} color1`} />
                                <Spacer height={`2vh`} />
                                <Text text={`${home4_caption1}`} fontSize="4vw" weight="300" color="" spacing="" lineHeight="" classes={``} />
                                <Spacer height={`4vh`} />
                                <Button color="" backgroundColor="" radius="" func={() => { navigate(`/${home4_buttonNav1}`) }} classes={``}>
                                    <Box width="fit-content" height="" radius="" backgroundColor="" paddingV="0.8em" paddingH="1.5em" id="" classes={`center light`}>
                                        <Text text={`${home4_buttonText1}`} fontSize="" weight="" color="" spacing="" lineHeight="" classes={``} />
                                    </Box>
                                </Button>
                            </Box>
                            <Box width="" height="60vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                <Image src={img1} alt="" radius="" classes={` fill`} />
                            </Box>
                        </Grid>
                        <Spacer height={`6vh`} />
                        <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                            <Box width="50vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={`center text-center`}>
                                <Text text={`${home4_heading2}`} fontSize="1.8em" weight="500" color="" spacing="" lineHeight="1em" classes={`${c_accentFont} color1`} />
                                <Spacer height={`2vh`} />
                                <Text text={`${home4_caption2}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={``} />
                                <Spacer height={`6vh`} />
                            </Box>
                            <Grid orientation="column" template="1fr 1fr" gap="1em" classes={``}>
                                {
                                    home4_subBlocks.map((block, i) => {
                                        return (
                                            <Box width="" height="" radius="" backgroundColor="rgba(0,0,80,0.05)" paddingV="1em" paddingH="" id="" classes={``}>
                                                <Text text={`${block.Heading}`} fontSize="1.2em" weight="400" color="" spacing="" lineHeight="" classes={`${c_accentFont} color1`} />
                                                <Spacer height={`2vh`} />
                                                <Text text={`${block.Caption}`} fontSize="0.9em" weight="300" color="" spacing="" lineHeight="" classes={``} />
                                            </Box>
                                        )
                                    })
                                }
                            </Grid>
                        </Box>
                    </Box>
                </TabletScreen>
                <LaptopScreen>
                    <Box width="" height="" radius="" backgroundColor="" paddingV="1em" paddingH="" id="" classes={``}>
                        <Grid orientation="column" template="1fr 1fr" gap="1em" classes={``}>
                            <Box width="" height="" radius="" backgroundColor="" paddingV="2vh" paddingH="2em" id="" classes={`dark v-separate`}>
                                <Spacer height={`2vh`} />
                                <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                    <Text text={`${home4_heading1}`} fontSize="4vw" weight="600" color="white" spacing="" lineHeight="1em" classes={`${c_accentFont} color1`} />
                                    <Spacer height={`2vh`} />
                                    <Text text={`${home4_caption1}`} fontSize="2vw" weight="300" color="" spacing="" lineHeight="" classes={``} />
                                    <Spacer height={`4vh`} />
                                    <Button color="" backgroundColor="" radius="" func={() => { navigate(`/${home4_buttonNav1}`) }} classes={``}>
                                        <Box width="fit-content" height="" radius="" backgroundColor="" paddingV="0.8em" paddingH="1.5em" id="" classes={`center light`}>
                                            <Text text={`${home4_buttonText1}`} fontSize="" weight="" color="" spacing="" lineHeight="" classes={``} />
                                        </Box>
                                    </Button>
                                </Box>
                                <Spacer height={`2vh`} />
                            </Box>
                            <Box width="" height="60vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                <Image src={img1} alt="" radius="" classes={` fill`} />
                            </Box>
                        </Grid>
                        <Spacer height={`6vh`} />
                        <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                            <Box width="50vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={`center text-center`}>
                                <Text text={`${home4_heading2}`} fontSize="1.8em" weight="500" color="" spacing="" lineHeight="1em" classes={`${c_accentFont} color1`} />
                                <Spacer height={`2vh`} />
                                <Text text={`${home4_caption2}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={``} />
                                <Spacer height={`6vh`} />
                            </Box>
                            <Grid orientation="column" template="1fr 1fr" gap="1em" classes={``}>
                                {
                                    home4_subBlocks.map((block, i) => {
                                        return (
                                            <Box width="" height="" radius="" backgroundColor="rgba(0,0,80,0.05)" paddingV="1em" paddingH="" id="" classes={``}>
                                                <Text text={`${block.Heading}`} fontSize="1.2em" weight="400" color="" spacing="" lineHeight="" classes={`${c_accentFont} color1`} />
                                                <Spacer height={`2vh`} />
                                                <Text text={`${block.Caption}`} fontSize="0.9em" weight="300" color="" spacing="" lineHeight="" classes={``} />
                                            </Box>
                                        )
                                    })
                                }
                            </Grid>
                        </Box>
                    </Box>
                </LaptopScreen>
                <DesktopScreen>
                    <Box width="" height="" radius="" backgroundColor="" paddingV="2em" paddingH="" id="" classes={``}>
                        <Grid orientation="column" template="1fr 1fr" gap="1em" classes={``}>
                            <Box width="" height="" radius="" backgroundColor="" paddingV="2vh" paddingH="4em" id="" classes={`dark v-separate`}>
                                <Spacer height={`2vh`} />
                                <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                    <Text text={`${home4_heading1}`} fontSize="3em" weight="600" color="white" spacing="" lineHeight="1em" classes={`${c_accentFont} color1`} />
                                    <Spacer height={`2vh`} />
                                    <Text text={`${home4_caption1}`} fontSize="1.4em" weight="300" color="" spacing="" lineHeight="" classes={``} />
                                    <Spacer height={`4vh`} />
                                    <Button color="" backgroundColor="" radius="" func={() => { navigate(`/${home4_buttonNav1}`) }} classes={``}>
                                        <Box width="fit-content" height="" radius="" backgroundColor="" paddingV="0.8em" paddingH="1.5em" id="" classes={`center light`}>
                                            <Text text={`${home4_buttonText1}`} fontSize="" weight="" color="" spacing="" lineHeight="" classes={``} />
                                        </Box>
                                    </Button>
                                </Box>
                                <Spacer height={`2vh`} />
                            </Box>
                            <Box width="" height="70vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                <Image src={img1} alt="" radius="" classes={` fill`} />
                            </Box>
                        </Grid>
                        <Spacer height={`6vh`} />
                        <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                            <Box width="50vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={`center text-center`}>
                                <Text text={`${home4_heading2}`} fontSize="1.8em" weight="500" color="" spacing="" lineHeight="1em" classes={`${c_accentFont} color1`} />
                                <Spacer height={`2vh`} />
                                <Text text={`${home4_caption2}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={``} />
                                <Spacer height={`6vh`} />
                            </Box>
                            <Grid orientation="column" template="1fr 1fr 1fr" gap="1em" classes={``}>
                                {
                                    home4_subBlocks.map((block, i) => {
                                        return (
                                            <Box width="" height="" radius="" backgroundColor="rgba(0,0,80,0.05)" paddingV="2em" paddingH="" id="" classes={``}>
                                                <Text text={`${block.Heading}`} fontSize="1.2em" weight="400" color="" spacing="" lineHeight="" classes={`${c_accentFont} color1`} />
                                                <Spacer height={`2vh`} />
                                                <Text text={`${block.Caption}`} fontSize="0.9em" weight="300" color="" spacing="" lineHeight="" classes={``} />
                                            </Box>
                                        )
                                    })
                                }
                            </Grid>
                        </Box>
                    </Box>
                </DesktopScreen>
            </ResponsiveWrapper>

            <Spacer height={`6vh`} />
            {c_footer()}
        </div>
    )
}