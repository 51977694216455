import React, { useEffect } from 'react'
import { Accordion, Border, Box, Button, Checkbox, DropDown, Grid, Icon, Image, Layer, RadioButtons, ResponsiveWrapper, PhoneScreen, TabletScreen, LaptopScreen, DesktopScreen, Text, TextArea, TextField, Underline, Spacer } from '../Elements'
import { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6 } from '../Components'
import { FiMenu } from 'react-icons/fi'
import { c_accentFont, c_businessName, c_footer, c_nav, c_textFont } from '../../Constants'
import { useLocation, useNavigate } from 'react-router'
// 
import img1 from '../../PHOTOS/stock4.jpg'
import img2 from '../../PHOTOS/stock5.jpg'
import { bio1_heading1, bio1_heading2, bio1_heading3, bio1_pageTitle, bio1_text1, bio1_text2, bio1_text3 } from '../../SprintConstants'

export default function Bio1() {
    const navigate = useNavigate()
    const location = useLocation()
    const currentLocation = location.pathname

    // function openNav() {
    //     if (window.innerWidth < 600) {
    //         document.querySelector(".nav-body").style.width = "100vw";
    //     } else if (window.innerWidth < 800) {
    //         document.querySelector(".nav-body").style.width = "50vw";
    //     } else if (window.innerWidth < 1000) {
    //         document.querySelector(".nav-body").style.width = "40vw";
    //     } else if (window.innerWidth < 1200) {
    //         document.querySelector(".nav-body").style.width = "35vw";
    //     } else {
    //         document.querySelector(".nav-body").style.width = "20vw";
    //     }
    // }

    // function closeNav() {
    //     document.querySelector(".nav-body").style.width = "0";
    // }
    

    useEffect(() => {
        window.scrollTo(0,0)
        // closeNav()
    }, [])

    return (
        <div className='roboto fade-in'>
            {c_nav()}
            <Box width="" height="" radius="" paddingV="1em" paddingH="1em" classes="">
                
                <Box width="85vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={`center text-center`}>
                <Heading1 text={`${bio1_pageTitle}`} classes={`${c_accentFont} color1`} />
                </Box>
                
                <Spacer height={`4vh`} />

                <ResponsiveWrapper>
                    <PhoneScreen>
                        <Grid orientation="column" template="1fr" gap="1em" classes="">
                            <Box width="" height="" radius="" paddingV="" paddingH="" classes="vertical gap">
                                <Text text={`${bio1_heading1}`} fontSize="1.6em" weight="600" color="" spacing="" lineHeight="1em" classes={`${c_accentFont}`} />
                                <Text text={`${bio1_text1}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />

                            </Box>
                            <Box width="" height="40vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="">
                                <Image src={img1} alt="" radius="" classes="fill" />
                            </Box>
                        </Grid>
                        <Spacer height={`4vh`} />
                        <Grid orientation="column" template="1fr" gap="1em" classes="">
                            <Box width="" height="40vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="">
                                <Image src={img2} alt="" radius="" classes="fill" />
                            </Box>
                            <Box width="" height="" radius="" paddingV="" paddingH="" classes="vertical gap">
                                <Text text={`${bio1_heading2}`} fontSize="1.6em" weight="600" color="" spacing="" lineHeight="1em" classes="" />
                                <Text text={`${bio1_text2}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                            </Box>
                        </Grid>
                        <Spacer height={`4vh`} />
                        <Grid orientation="column" template="1fr" gap="1em" classes="">
                            <Box width="" height="" radius="" paddingV="" paddingH="" classes="vertical gap">
                                <Text text={`${bio1_heading3}`} fontSize="1.6em" weight="600" color="" spacing="" lineHeight="1em" classes="" />
                                <Text text={`${bio1_text3}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />

                            </Box>
                            <Box width="" height="40vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="">
                                <Image src={img1} alt="" radius="" classes="fill" />
                            </Box>
                        </Grid>
                    </PhoneScreen>
                    <TabletScreen>
                        <Grid orientation="column" template="1fr" gap="1em" classes="">
                            <Box width="" height="" radius="" paddingV="" paddingH="" classes="vertical gap">
                                <Text text={`${bio1_heading1}`} fontSize="1.6em" weight="600" color="" spacing="" lineHeight="1em" classes={`${c_accentFont}`} />
                                <Text text={`${bio1_text1}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />

                            </Box>
                            <Box width="" height="40vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="">
                            <Image src={img1} alt="" radius="" classes="fill" />
                            </Box>
                        </Grid>
                        <Spacer height={`4vh`} />
                        <Grid orientation="column" template="1fr" gap="1em" classes="">
                            <Box width="" height="40vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="">
                                <Image src={img2} alt="" radius="" classes="fill" />
                            </Box>
                            <Box width="" height="" radius="" paddingV="" paddingH="" classes="vertical gap">
                                <Text text={`${bio1_heading2}`} fontSize="1.6em" weight="600" color="" spacing="" lineHeight="1em" classes={`${c_accentFont}`} />
                                <Text text={`${bio1_text2}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                            </Box>
                        </Grid>
                        <Spacer height={`4vh`} />
                        <Grid orientation="column" template="1fr" gap="1em" classes="">
                            <Box width="" height="" radius="" paddingV="" paddingH="" classes="vertical gap">
                                <Text text={`${bio1_heading3}`} fontSize="1.6em" weight="600" color="" spacing="" lineHeight="1em" classes={`${c_accentFont}`} />
                                <Text text={`${bio1_text3}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />

                            </Box>
                            <Box width="" height="40vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="">
                                <Image src={img1} alt="" radius="" classes="fill" />
                            </Box>
                        </Grid>
                    </TabletScreen>
                    <LaptopScreen>
                        <Grid orientation="column" template="1fr 1fr" gap="1em" classes="">
                            <Box width="" height="" radius="" paddingV="" paddingH="" classes="vertical gap">
                                <Text text={`${bio1_heading1}`} fontSize="1.6em" weight="600" color="" spacing="" lineHeight="1em" classes={`${c_accentFont}`} />
                                <Text text={`${bio1_text1}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />

                            </Box>
                            
                        </Grid>
                        <Spacer height={`4vh`} />
                        <Grid orientation="column" template="1fr 1fr" gap="1em" classes="">
                            <Image src={img2} alt="" radius="" classes="fill" />
                            <Box width="" height="" radius="" paddingV="" paddingH="" classes="vertical gap">
                                <Text text={`${bio1_heading2}`} fontSize="1.6em" weight="600" color="" spacing="" lineHeight="1em" classes={`${c_accentFont}`} />
                                <Text text={`${bio1_text2}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                            </Box>
                        </Grid>
                        <Spacer height={`4vh`} />
                        <Box width="90vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="center">
                            <Grid orientation="column" template="1fr" gap="1em" classes="">
                                <Box width="" height="" radius="" paddingV="" paddingH="" classes="vertical gap">
                                    <Text text={`${bio1_heading3}`} fontSize="1.6em" weight="600" color="" spacing="" lineHeight="1em" classes={`${c_accentFont}`} />
                                    <Text text={`${bio1_text3}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />

                                </Box>
                                <Image src={img1} alt="" radius="" classes="fill" />
                            </Grid>
                        </Box>
                    </LaptopScreen>
                    <DesktopScreen>
                        <Grid orientation="column" template="1fr 1fr" gap="1em" classes="">
                            <Box width="" height="" radius="" paddingV="" paddingH="" classes="vertical gap">
                                <Text text={`${bio1_heading1}`} fontSize="2em" weight="600" color="" spacing="" lineHeight="1em" classes={`${c_accentFont}`} />
                                <Text text={`${bio1_text1}`} fontSize="1.4em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                            </Box>
                            <Box width="" height="50vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="">
                            <Image src={img1} alt="" radius="" classes="fill" />
                            </Box>
                        </Grid>
                        <Spacer height={`4vh`} />
                        <Grid orientation="column" template="1fr 1fr" gap="1em" classes="">
                            <Image src={img2} alt="" radius="" classes="fill" />
                            <Box width="" height="" radius="" paddingV="" paddingH="" classes="vertical gap">
                                <Text text={`${bio1_heading2}`} fontSize="2em" weight="600" color="" spacing="" lineHeight="1em" classes={`${c_accentFont}`} />
                                <Text text={`${bio1_text2}`} fontSize="1.4em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                            </Box>
                        </Grid>
                        <Spacer height={`6vh`} />
                        <Box width="70vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="center">
                            <Grid orientation="column" template="1fr" gap="1em" classes="">
                                <Box width="" height="" radius="" paddingV="" paddingH="" classes="vertical gap">
                                    <Text text={`${bio1_heading3}`} fontSize="2em" weight="600" color="" spacing="" lineHeight="1em" classes={`${c_accentFont}`} />
                                    <Text text={`${bio1_text3}`} fontSize="1.4em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />

                                </Box>
                                <Box width="" height="60vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="">
                                <Image src={img1} alt="" radius="" classes="fill" />
                                </Box>
                            </Grid>
                        </Box>
                    </DesktopScreen>
                </ResponsiveWrapper>

            </Box>

            <Spacer height={`6vh`} />
            {c_footer()}
        </div>
    )
}