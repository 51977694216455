import React, { useEffect } from 'react'
import { Accordion, Border, Box, Button, Checkbox, DropDown, Grid, Icon, Image, Layer, RadioButtons, ResponsiveWrapper, PhoneScreen, TabletScreen, LaptopScreen, DesktopScreen, Text, TextArea, TextField, Underline, Spacer } from '../Elements'
import { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6 } from '../Components'
import { FiMenu } from 'react-icons/fi'
import { c_accentFont, c_businessName, c_footer, c_nav, c_routes, c_textFont } from '../../Constants'
import { useLocation, useNavigate } from 'react-router'
import Navigation1 from './UTILITIES/Navigation1'
import Footer1 from './UTILITIES/Footer1'
// 
import img1 from '../../PHOTOS/stock5.jpg'
import { services1_caption, services1_pageTitle, services1_services } from '../../SprintConstants'

export default function Services1() {
    const navigate = useNavigate()
    const location = useLocation()
    const currentLocation = location.pathname

    // function openNav() {
    //     if (window.innerWidth < 600) {
    //         document.querySelector(".nav-body").style.width = "100vw";
    //     } else if (window.innerWidth < 800) {
    //         document.querySelector(".nav-body").style.width = "50vw";
    //     } else if (window.innerWidth < 1000) {
    //         document.querySelector(".nav-body").style.width = "40vw";
    //     } else if (window.innerWidth < 1200) {
    //         document.querySelector(".nav-body").style.width = "35vw";
    //     } else {
    //         document.querySelector(".nav-body").style.width = "20vw";
    //     }
    // }

    // function closeNav() {
    //     document.querySelector(".nav-body").style.width = "0";
    // }

  

    useEffect(() => {
        window.scrollTo(0,0)
        // closeNav()
    }, [])

    return (
        <div className='roboto fade-in'>
            {c_nav()}

            <Box width="" height="" radius="" paddingV="1em" paddingH="1em" classes="">
                <Box width="90vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="center">
                    <Heading1 text={`${services1_pageTitle}`} classes={`${c_accentFont} color1`} />
                    <Spacer height={`2vh`} />
                    <Heading5 text={`${services1_caption}`} classes={`${c_textFont}`} />
                </Box>

                <Spacer height={`4vh`} />

                <ResponsiveWrapper>
                    <PhoneScreen>
                        <Grid orientation="column" template="1fr" gap="1.2em" classes="">
                            {
                                services1_services.map((service, i) => {
                                    return (
                                        <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                                            <Box width="" height="35vh" radius="" paddingV="" paddingH="" classes="">
                                                <Image src={service.Image} alt="" radius="" classes="fill" />
                                            </Box>
                                            <Spacer height={`1vh`} />
                                            <Text text={`${service.Name}`} fontSize="1.6em" weight="400" color="" spacing="" lineHeight="" classes={`${c_accentFont} color1`} />
                                            <Text text={`${service.Desc}`} fontSize="1em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                        </Box>
                                    )
                                })
                            }
                        </Grid>
                    </PhoneScreen>
                    <TabletScreen>
                        <Grid orientation="column" template="1fr 1fr" gap="1.2em" classes="">
                            {
                                services1_services.map((service, i) => {
                                    return (
                                        <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                                            <Box width="" height="30vh" radius="" paddingV="" paddingH="" classes="">
                                                <Image src={service.Image} alt="" radius="" classes="fill" />
                                            </Box>
                                            <Spacer height={`1vh`} />
                                            <Text text={`${service.Name}`} fontSize="1.6em" weight="400" color="" spacing="" lineHeight="" classes={`${c_accentFont} color1`} />
                                            <Text text={`${service.Desc}`} fontSize="1em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                        </Box>
                                    )
                                })
                            }
                        </Grid>
                    </TabletScreen>
                    <LaptopScreen>
                        <Grid orientation="column" template="1fr 1fr" gap="1.2em" classes="">
                            {
                                services1_services.map((service, i) => {
                                    return (
                                        <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                                            <Box width="" height="35vh" radius="" paddingV="" paddingH="" classes="">
                                                <Image src={service.Image} alt="" radius="" classes="fill" />
                                            </Box>
                                            <Spacer height={`1vh`} />
                                            <Text text={`${service.Name}`} fontSize="1.6em" weight="400" color="" spacing="" lineHeight="" classes={`${c_accentFont} color1`} />
                                            <Text text={`${service.Desc}`} fontSize="1em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                        </Box>
                                    )
                                })
                            }
                        </Grid>
                    </LaptopScreen>
                    <DesktopScreen>
                        <Box width="85vw" height="" radius="" paddingV="4vh" paddingH="0" classes="center">
                            <Grid orientation="column" template="1fr 1fr 1fr" gap="1.5em" classes="">
                                {
                                    services1_services.map((service, i) => {
                                        return (
                                            <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                                                <Box width="" height="30vh" radius="" paddingV="" paddingH="" classes="">
                                                    <Image src={service.Image} alt="" radius="" classes="fill" />
                                                </Box>
                                                <Spacer height={`1vh`} />
                                                <Text text={`${service.Name}`} fontSize="1.6em" weight="400" color="" spacing="" lineHeight="" classes={`${c_accentFont} color1`} />
                                                <Text text={`${service.Desc}`} fontSize="1em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                            </Box>
                                        )
                                    })
                                }
                            </Grid>
                        </Box>
                    </DesktopScreen>
                </ResponsiveWrapper>
            </Box>
            <Spacer height={`6vh`} />
           {c_footer()}
        </div>
    )
}